<template>
  <base-section
    id="info-alt"
    space="56"
    style="text-align: center; font-size: 32px"
  >
    <v-row style="margin: 0px; padding: 16px" justify="center">
      <span
        style="
          font-size: 3rem;
          font-weight: 700;
          max-width: 600px;
          text-align: center;
        "
      >
        {{ $t("contactUs.title") }}
      </span></v-row
    ><v-row style="margin: 0px; padding: 16px" justify="center"
      ><span
        style="
          font-size: 1rem;
          font-weight: 700;
          max-width: 600px;
          text-align: center;
        "
      >
      {{ $t("contactUs.subTitlePart1") }}
      <span style="color: rgb(170, 0, 76)"> info@tentifly.com</span>
      {{ $t("contactUs.subTitlePart2") }}
        <span style="color: rgb(170, 0, 76)"
          > +372 5880<span style="opacity: 0">i</span>5006 </span
        >
        {{ $t("contactUs.subTitlePart3") }}
      </span>
    </v-row>
    <v-row justify="center">
      <v-form
        ref="form"
        style="padding: 16px; max-width: 640px"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="name"
          :placeholder="$t('contactUs.namePlaceholder')"
          :rules="nameRules"
          :label="$t('contactUs.name')"
          required
        ></v-text-field>

        <v-text-field
          v-model="company"
          :placeholder="$t('contactUs.companyPlaceholder')"
          :label="$t('contactUs.company')"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          placeholder="john@example.com"
          :label="$t('contactUs.email')"
          required
        ></v-text-field>

        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          placeholder="372 5555 5555"
          :label="$t('contactUs.phone')"
          required
        ></v-text-field>

        <v-textarea
          v-model="text"
          :rules="textRules"
          :label="$t('contactUs.inquiry')"
          :placeholder="$t('contactUs.inquiryPlaceholder')"
          required
        ></v-textarea>

        <v-checkbox
          v-model="formPrivacyAgreement"
          :rules="[
            (v) =>
              !!v ||
              $t('contactUs.privacyError'),
          ]"
          :label="$t('contactUs.privacyNotice')"
          required
        ></v-checkbox>

        <base-btn
          :disabled="!valid"
          color="primary"
          class="mr-4"
          @click="validate"
        >
        {{ $t("contactUs.submit") }}
        </base-btn>
      </v-form>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="#aa004c"
      rounded="pill"
    >
      {{ this.snackBarText }}
    </v-snackbar>
  </base-section>
</template>

<script>
import axios from "axios";

export default {
  name: "SignUp",
  methods: {
    validate() {
      this.$refs.form.validate();
      console.log("validated");
      if (this.valid) {
        this.sendInquiry()
      }
    },
    sendInquiry() {
      let messageBody = 'Name:' + this.name + '<br>Company:' + this.company + '<br>Email:' + this.email + '<br>Phone:' + this.phone + '<br>Text:' + this.text;
      axios
        .post(`https://v2.tentifly.com/inquiry`, {
          message: messageBody,
        })
        .then((response) => {
          this.snackbar = true
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  data: () => ({
    valid: false,
    snackBarText: "We have received your message :)",
    snackbar: false,
    name: "",
    nameRules: [v => !!v || "Name is required"],
    company: "",
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid."
    ],
    text: "",
    textRules: [v => !!v || "Inquiry is required"],
    phone: "",
    phoneRules: [v => !!v || "Phone is required"],
    formPrivacyAgreement: false
  })
};
</script>
